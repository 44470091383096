/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */

/* position */
.toast-center-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.toast-top-center {
    top: 0;
    right: 0;
    width: 100%;
}
.toast-bottom-center {
    bottom: 0;
    right: 0;
    width: 100%;
}
.toast-top-full-width {
    top: 0;
    right: 0;
    width: 100%;
}
.toast-bottom-full-width {
    bottom: 0;
    right: 0;
    width: 100%;
}
.toast-top-left {
    top: 12px;
    left: 12px;
}
.toast-top-right {
    top: 12px;
    right: 12px;
}
.toast-bottom-right {
    right: 12px;
    bottom: 12px;
}
.toast-bottom-left {
    bottom: 12px;
    left: 12px;
}
.inline-toast-top-right {
    position: absolute !important;
    right: 12px;
}

/* toast styles */
.toast-title {
    font-weight: bold;
}
.toast-message {
    word-wrap: break-word;
}
.toast-message a,
.toast-message label {
    color: #FFFFFF;
}
.toast-message a:hover {
    color: #CCCCCC;
    text-decoration: none;
}
.toast-close-button {
    position: relative;
    right: -0.3em;
    top: -0.3em;
    float: right;
    font-size: 20px;
    font-weight: bold;
    color: #FFFFFF;
    text-shadow: 0 1px 0 #ffffff;
    /* opacity: 0.8; */
}
.toast-close-button:hover,
.toast-close-button:focus {
    color: #000000;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.4;
}
/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
}
.toast-container {
    pointer-events: none;
    position: fixed;
    z-index: 999999;
}
.toast-container * {
    box-sizing: border-box;
}
.toast-container .ngx-toastr {
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 25px 15px 25px 80px;
    width: 400px;
    max-width: 100vw;
    border-radius: 3px 3px 3px 3px;
    background-position: 15px center;
    background-repeat: no-repeat;
    background-size: 44px;
    box-shadow: 0 0 12px #999999;
    @apply text-secondary #{'!important'};
}
.toast-container .ngx-toastr:hover {
    box-shadow: 0 0 12px #000000;
    opacity: 1;
    cursor: pointer;
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
    background-image: url("data:image/svg+xml,%3Csvg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='22' cy='22' r='22' fill='url(%23paint0_linear)'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20 31C20 32.1046 20.8954 33 22 33C23.1046 33 24 32.1046 24 31L24 20C24 18.8954 23.1046 18 22 18C20.8954 18 20 18.8954 20 20L20 31ZM22 15C23.1046 15 24 14.1046 24 13C24 11.8954 23.1046 11 22 11C20.8954 11 20 11.8954 20 13C20 14.1046 20.8954 15 22 15Z' fill='white'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='-1.5' y1='23.5' x2='48.5' y2='22' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.239484' stop-color='%233D6BE0'/%3E%3Cstop offset='1' stop-color='%2339C0AE'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");

    .toast-title {
        @apply text-blue-500 #{'!important'};
    }

    button.toast-close-button {
        @apply text-blue-500 #{'!important'};
    }
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
    background-image: url("data:image/svg+xml,%3Csvg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='22' cy='22' r='22' fill='url(%23paint0_linear_0_1)'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.4246 14.4246C16.2057 13.6436 17.472 13.6436 18.253 14.4246L30.2739 26.4454C31.0549 27.2265 31.0549 28.4928 30.2739 29.2739C29.4928 30.0549 28.2265 30.0549 27.4454 29.2739L15.4246 17.2531C14.6436 16.472 14.6436 15.2057 15.4246 14.4246Z' fill='white'/%3E%3Cline x1='16.4853' y1='27.5061' x2='28.5061' y2='15.4853' stroke='white' stroke-width='4' stroke-linecap='round'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_0_1' x1='-1.5' y1='23.5' x2='48.5' y2='22' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.239484' stop-color='%23E0413D'/%3E%3Cstop offset='1' stop-color='%23F5A60C'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");

    .toast-title {
        @apply text-red-500 #{'!important'};
    }

    button.toast-close-button {
        @apply text-red-500 #{'!important'};
    }
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
    background-image: url("data:image/svg+xml,%3Csvg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='22' cy='22' r='22' fill='url(%23paint0_linear_0_1)'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M34.4142 17.2426C35.1953 16.4616 35.1953 15.1953 34.4142 14.4142C33.6332 13.6332 32.3668 13.6332 31.5858 14.4142L19.6465 26.3535L13.4142 20.1213C12.6332 19.3402 11.3668 19.3402 10.5858 20.1213C9.80474 20.9023 9.80474 22.1687 10.5858 22.9497L18.0858 30.4497C18.5733 30.9373 19.25 31.1205 19.8796 30.9994C20.3743 30.9868 20.8651 30.7917 21.2426 30.4142L34.4142 17.2426Z' fill='white'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_0_1' x1='-1.5' y1='23.5' x2='48.5' y2='22' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.239484' stop-color='%2399CC58'/%3E%3Cstop offset='1' stop-color='%231FBDC5'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
    
    .toast-title {
        @apply text-green-500 #{'!important'};
    }

    button.toast-close-button {
        @apply text-green-500 #{'!important'};
    }
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
    background-image: url("data:image/svg+xml,%3Csvg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='22' cy='22' r='22' fill='url(%23paint0_linear)'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M24 13C24 11.8954 23.1046 11 22 11C20.8954 11 20 11.8954 20 13V27C20 28.1046 20.8954 29 22 29C23.1046 29 24 28.1046 24 27V13ZM22 32C20.8954 32 20 32.8954 20 34C20 35.1046 20.8954 36 22 36C23.1046 36 24 35.1046 24 34C24 32.8954 23.1046 32 22 32Z' fill='white'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='-1.5' y1='23.5' x2='48.5' y2='22' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.239484' stop-color='%23F29713'/%3E%3Cstop offset='1' stop-color='%23F1F50C'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");

    .toast-title {
        @apply text-orange-500 #{'!important'};
    }

    button.toast-close-button {
        @apply text-orange-500 #{'!important'};
    }
}
.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
}
.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
    width: 96%;
    margin-left: auto;
    margin-right: auto;
}
.ngx-toastr {
    background-color: #030303;
    pointer-events: auto;
}
.toast-success {
    @apply bg-card #{'!important'};
    @apply border-l-8 #{'!important'};
    @apply border-green-400 #{'!important'};
}
.toast-error {
    @apply bg-card #{'!important'};
    @apply border-l-8 #{'!important'};
    @apply border-red-500 #{'!important'};
}
.toast-info {
    @apply bg-card #{'!important'};
    @apply border-l-8 #{'!important'};
    @apply border-blue-400 #{'!important'};
}
.toast-warning {
    @apply bg-card #{'!important'};
    @apply border-l-8 #{'!important'};
    @apply border-orange-400 #{'!important'};
}
.toast-progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    background-color: #000000;
    opacity: 0.4;
}
/* Responsive Design */
@media all and (max-width: 240px) {
    .toast-container .ngx-toastr.div {
        padding: 8px 8px 8px 50px;
        width: 11em;
    }
    .toast-container .toast-close-button {
        right: -0.2em;
        top: -0.2em;
    }
}
@media all and (min-width: 241px) and (max-width: 480px) {
    .toast-container .ngx-toastr.div {
        padding: 8px 8px 8px 50px;
        width: 18em;
    }
    .toast-container .toast-close-button {
        right: -0.2em;
        top: -0.2em;
    }
}
@media all and (min-width: 481px) and (max-width: 768px) {
    .toast-container .ngx-toastr.div {
        padding: 15px 15px 15px 50px;
        width: 25em;
    }
}
