.info-box {
  background: rgba(39, 192, 200, 0.05);
}


/* BADGES */
.count-badge {
  @apply hidden sm:block text-sm w-5 h-5 leading-5 text-secondary text-center -mt-1.5 bg-slate-200 rounded-full;
  opacity: 0.4;

  &.active {
    opacity: 1;
  }
}

.index-badge {
  @apply text-sm w-5 h-5 leading-5 text-white text-center bg-teal-400 rounded-full;
}


/* BUTTONS */
.show-more-btn {
  @apply w-22 flex items-center justify-center border-2 border-teal-400 text-teal-400 text-xs rounded-full bg-card;
}


.no-items-yet {
  @apply pb-4 text-center text-blue-500;
}

.glow-on-hover {
  width: 150px;
  height: 25px;
  line-height: 25px;
  border: none;
  outline: none;
  color: #fff;
  background: #fff;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}

.glow-on-hover:before {
  content: '';
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
  position: absolute;
  top: -2px;
  left:-2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  color: #000
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}

/* CARDS */
.teal-bordered-card {
  @apply flex flex-col p-3.5 mb-5 border-2 rounded border-teal-400 relative;

  .card-actions-bottom {
    @apply bg-card px-1.5 inline-block absolute;
    bottom: -9px;
    left: calc(50% - 44px);
  }
}

.slate-bordered-card {
  @apply flex flex-col p-3.5 mb-5 border-2 rounded relative;

  border: 2px dashed #bcc0c5 !important;

  &.active {
    @apply bg-blue-50;

    .bg-slate-100 { background-color: white !important; }

    .text-slate-500 { color: #333 !important; }
  }
}

.neutral-bordered-card {
  @apply flex flex-col p-3.5 mb-5 border rounded border-neutral-300 relative;

  .card-actions-bottom {
    @apply bg-card px-1.5 inline-block absolute;
    bottom: -9px;
    left: calc(50% - 44px);
  }
}

/* STEPPERS AND CHAINS */
.pretty-stepper {
  @apply flex items-center w-full;

  .step {
    @apply flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-100 after:border-4 after:inline-block dark:after:border-gray-700 cursor-pointer;

    span {
      @apply flex items-center justify-center w-10 h-10 bg-gray-100 hover:bg-gray-200 rounded-full lg:h-12 lg:w-12 dark:bg-gray-700 shrink-0;
    }
  }

  .last-step {
    @apply flex items-center cursor-pointer;

    span {
      @apply flex items-center justify-center w-10 h-10 bg-gray-100 hover:bg-gray-200 rounded-full lg:h-12 lg:w-12 dark:bg-gray-700 shrink-0;
    }
  }

  .active {
    @apply text-blue-600 after:border-blue-100 dark:after:border-blue-800;

    span {
      @apply bg-blue-100 dark:bg-blue-800;
    }
  }
}

.pretty-stepper-timeline {
  @apply flex items-center w-full;

  .step {
    @apply relative flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-100 after:border-8 after:inline-block dark:after:border-gray-700;

    span {
      @apply flex items-center justify-center w-14 h-6 leading-6 bg-slate-200 rounded;
    }

    div {
      @apply absolute top-7 w-24 text-slate-500 text-sm -ml-10 text-center;
    }

    i {
      @apply w-3 h-3 bg-gray-500 rounded-full;
    }
  }

  .text {
    @apply absolute top-5;
  }

  .active {
    @apply text-blue-600 after:border-blue-100 dark:after:border-blue-800;

    span {
      @apply bg-blue-100 dark:bg-blue-800;
    }
  }
}


.pretty-stepper-header {
  @apply text-center text-secondary mb-4 font-bold text-xl;
}

.pretty-chain {
  @apply flex items-center w-full;

  .step {
    @apply relative flex items-center after:content-[''] after:w-9 after:h-1 after:border-b after:border-dashed after:border-slate-500 after:inline-block dark:after:border-gray-700 cursor-pointer;

    div:first-child {
      @apply flex space-x-2 px-4 items-center justify-center h-9 bg-slate-200 text-slate-500 rounded-full dark:bg-gray-700 shrink-0;
    }
  }

  .last-step {
    @apply relative flex items-center cursor-pointer;

    div:first-child {
      @apply flex space-x-2 items-center justify-center w-48 h-9 bg-gray-200 rounded-full dark:bg-gray-700 shrink-0;
    }
  }

  .step+.step:before, .step+.last-step:before {
    content: "";
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 5px solid #64748B;
    margin-top: 3px;
  }

  .hint {
    position: absolute;
    bottom: -16px;
  }
}



/* Pill-Buttons */
.pill-teal-blue {
  @apply flex space-x-2 items-center justify-center rounded-full w-50 h-9 bg-teal-blue-500 text-white #{'!important'};

  mat-icon { @apply text-white #{'!important'}; }
}

.pill-lime {
  @apply flex space-x-2 items-center justify-center rounded-full w-50 h-9 bg-[#95CB5B] text-white #{'!important'};

  mat-icon { @apply text-white #{'!important'}; }
}


/* Tables */

.datatable-theme-modern {
  .ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group {
    background-color: #fff !important;
    @apply border border-teal-blue-500;
  }

  ngx-datatable datatable-scroller {
    overflow-x: hidden;
  }

  ngx-datatable .datatable-header-cell-label {
    font-weight: normal !important;
    @apply text-secondary;
  }

  .ngx-datatable.material {
    box-shadow: none !important;;
    background: transparent !important;
  }

  .ngx-datatable .datatable-body .datatable-body-row {
    outline: none;
    background: #fff;
    margin-bottom: 2px;
    border-radius: 6px;
    padding: 0;
  }

  .ngx-datatable.material .datatable-header {
    background: #fff;
    border-radius: 6px;
  }

  .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
    text-align: left;
    padding: 0.4rem 0.7rem;
  }

  .ngx-datatable.material .datatable-body .datatable-row-left {
    border-width: 1.5px;
    border-color: #64748B;
  }

  .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover, .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
    background-color: rgb(244, 244, 248);
  }

  .datatable-footer {
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 1px 1px #eeeeee;
  }

  .ngx-datatable.material .empty-row {
    background-color: #fff;
    border-radius: 6px;
  }

  .ngx-datatable.material .datatable-header .datatable-row-right, .ngx-datatable.material .datatable-body .datatable-row-right {
    transform: translate3d(-3px, 0px, 0px) !important;
  }
}


.datatable-theme-modern-b {
  .ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group {
    background-color: #E0EAF4 !important;
    @apply border border-teal-blue-500;
  }

  ngx-datatable datatable-scroller {
    overflow-x: hidden;
  }

  ngx-datatable .datatable-header-cell-label {
    font-weight: normal !important;
    @apply text-secondary;
  }

  .ngx-datatable.material {
    box-shadow: none !important;;
    background: transparent !important;
  }

  .ngx-datatable .datatable-body .datatable-body-row {
    outline: none;
    margin-bottom: 4px;
  }

  .ngx-datatable.material .datatable-header {
    background: #fff;
    border-radius: 6px;
  }

  .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
    background: #fff;
    text-align: left;
    padding: 15px !important;
  }

  .ngx-datatable.material .datatable-body .datatable-row-left {
    border-width: 1.5px;
    border-color: transparent;
  }

  .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover, .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
    background-color: rgb(244, 244, 248);
  }

  .datatable-footer {
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 1px 1px #eeeeee;
  }

  .ngx-datatable.material .empty-row {
    background-color: #fff;
    border-radius: 6px;
  }

  .ngx-datatable.material .datatable-header .datatable-row-right, .ngx-datatable.material .datatable-body .datatable-row-right {
    transform: translate3d(-3px, 0px, 0px) !important;
  }
}

.table-theme-white {
  @apply bg-slate-100 p-2 py-4 flex flex-col gap-y-1;

  div.header {
    @apply flex bg-card rounded-md;
    &> div { @apply pt-4 px-1 text-secondary text-center; }
  }

  div.row {
    @apply flex bg-card rounded-md divide-x;
    &> div { @apply pt-5 leading-5 pb-1 min-h-15 px-1 text-center; }
    &> div:first-child { @apply text-left px-4 text-lg font-bold; }
  }
}

/** Tabs **/
.tabs-slate-100 {
  .mdc-tab--active {
    @apply bg-slate-100;
    padding-left: 10px !important;
    clip-path: polygon(0 0, 80% 0%, 100% 100%, 100% 100%, 0 100%);
  }

  .mdc-tab__content { margin-left: -30px; }
  .mdc-tab-indicator { display: none;  }
  .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label { color:#333 !important; }
  .mat-mdc-tab .mdc-tab__text-label {
    @apply font-caveat text-3xl;
  }
}